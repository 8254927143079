<template>
  <PageLayout>
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-one-third">
            <div class="has-padding">
              <div class="box">
                <transition
                  name="fade"
                  mode="out-in"
                >
                  <SetPassword
                    key="setnewpassword"
                    v-if="!passwordSet"
                    @nextAction="nexAction"
                  />
                  <SetPasswordConfirmation
                    v-else
                    key="setnewpasswordConfirmation"
                  />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </PageLayout>
</template>

<script>
const PageLayout = () => import(/* webpackChunkName: "my-account-setpassword" */ '../../../components/MainSiteElements/Frontend/PageLayout.vue')
const SetPassword = () => import(/* webpackChunkName: "my-account-setpassword" */ '@/components/AccountControls/SetPassword')
const SetPasswordConfirmation = () => import(/* webpackChunkName: "my-account-setpassword" */ '@/components/AccountControls/SetPasswordConfirmation')
export default {
  name: 'view-accounts-set-password',

  components: {
    PageLayout,
    SetPassword,
    SetPasswordConfirmation
  },

  data() {
    return {
      passwordSet: false
    }
  },

  methods: {
    nexAction() {
      this.passwordSet = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>